<template>
    <div>
        <i-table stripe  :data="history" :columns="columns"></i-table>
    </div>
</template>

<script>
import { getReceiveList } from '@/api/scp/contract'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    relateId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      columns: [
        { title: '日期', key: 'date' },
        {
          title: '分配金额',
          key: 'receiveAmount',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.amount))
          }
        }
      ],
      history: []
    }
  },
  methods: {
    getHistory () {
      getReceiveList({ contractId: this.relateId }).then(res => {
        this.history = res
      })
    }
  },
  created () {
    this.getHistory()
  }

}
</script>
