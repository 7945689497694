import request from '@/utils/requestV2'
const qs = require('qs')

// 新增一次开票申请
export function addInvoiceApplication (data) {
  return request({
    url: '/ooh-scp/v1/invoiceapplication/addinvoiceapplication',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取开票申请详情
export function getInvoiceApplication (data) {
  return request({
    url: '/ooh-scp/v1/invoiceapplication/getinvoiceapplication',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取某主体的开票申请
export function getInvoiceApplicationsList (data) {
  return request({
    url: '/ooh-scp/v1/invoiceapplication/getinvoiceapplicationslist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询开票申请信息，分页显示
export function getInvoiceApplicationsPage (data) {
  return request({
    url: '/ooh-scp/v1/invoiceapplication/getinvoiceapplicationspage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作废某次开票申请
export function invalidInvoiceApplication (data) {
  return request({
    url: '/ooh-scp/v1/invoiceapplication/invalidinvoiceapplication',
    method: 'post',
    data: qs.stringify(data)
  })
}
