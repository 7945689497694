<template>
  <div>
    <Row v-for="(item,i) in applications" :key="i" :gutter="10" class="m-t-5">
      <i-col span="7">
        <InputNumber placeholder="请输入发票金额"  size="small" v-model="item.amount" :max="item.max" :min="0"  @on-blur="handleChange(item,i)" style="width:90%"></InputNumber>
        元
      </i-col>
      <i-col span="7">
        <i-select v-model="item.type" @change="handleChange(item,i)"  size="small">
          <i-option :value="0">专票</i-option>
          <i-option :value="1">普票</i-option>
        </i-select>
      </i-col>
       <i-col span="7">
        <!-- <i-input placeholder="费用类型：宣传费、制作费等" v-model="item.content" number @on-blur="handleChange(item,i)"></i-input> -->
        <Select v-model="item.content" @on-change="handleChange(item,i)"  size="small" clearable placeholder="请选择费用类型">
          <Option v-for="(item,index) in feeTypeArray" :key="'feeType_'+index" :value="item.value">{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <a style="color:#ef4f4f;"  size="small" @click="handleDelete(item,i)">删除</a>
      </i-col>
    </Row>

    <Row class="m-t-5">
      <i-col span="24">
        <p v-show="unInvoiceAmount>0">
          <span class="text-red">该合同您还有 {{formatMoney(unInvoiceAmount)}} 元 未申请开票</span>
        </p>
        <p v-show="unInvoiceAmount<0">
          <span class="text-red">您申请金额已经超出该合同 {{formatMoney(Math.abs(unInvoiceAmount) )}} 元 </span>
        </p>
      </i-col>
    </Row>
    <Row class="p-t-5">
      <i-button type="primary" size="small"  @click="handleAdd">增加开票项</i-button>
      <i-button type="success"  size="small" class="m-l-5" :loading="loadingSubmit" :disabled="disabledSubmit"  @click="handleAddApplication">保存提交</i-button>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    money: {
      type: Number
    }
  },
  data () {
    return {
      loadingSubmit: false,
      applications: [],
      feeTypeArray: [
        { value: '广告发布费', name: '广告发布费' },
        { value: '广告服务费', name: '广告服务费' },
        { value: '广告代理费', name: '广告代理费' }
      ]
    }
  },
  methods: {
    handleDelete (item, i) {
      this.applications.splice(i, 1)
      this.$emit('on-update', this.applications)
    },
    handleAdd () {
      this.applications.push({ type: 0, amount: 0, content: '' })
      this.$emit('on-update', this.applications)
    },
    handleChange (item, i) {
      this.applications.splice(i, 1, item)
      this.$emit('on-update', this.applications)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleAddApplication () {
      if (this.loadingSubmit) { return }

      this.loadingSubmit = true
      this.$emit('on-submit')
    },
    clearLoading () {
      this.loadingSubmit = false
    }
  },
  created () {
    this.applications = [{ type: 0, amount: this.money, content: '' }]
    this.$emit('on-update', this.applications)
  },
  computed: {
    unInvoiceAmount: function () {
      let total = 0
      this.applications.forEach(item => {
        total += parseFloat(item.amount)
      })

      return this.money - total
    },
    disabledSubmit: function () {
      if (this.unInvoiceAmount < 0) return true

      let disabled = false
      this.applications.forEach(item => {
        if (item.amount <= 0) {
          disabled = true
        }
      })

      return disabled
    }
  }
}
</script>
